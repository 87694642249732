import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';
import { createDescriptionOptions } from '../../utils/markdownTailwind';

const Image = ({ data }) => {
  return (
    <div className="mt-8 lg:mt-0 lg:w-1/2">
      <div className="flex items-center justify-center lg:justify-end">
        <div className="max-w-lg">
          <img
            loading={'lazy'}
            className="object-cover object-center w-full h-64 rounded-md shadow"
            src={data.imgSrc}
            alt={data?.altDescription || 'image that contains view of Alexandroupolis'}
          />
        </div>
      </div>
    </div>
  );
};

const InfoLarge = ({ data, index }) => {
  const { content, order, subtitle, title } = data;
  return (
    <section
      className="bg-transparent dark:bg-aegean text-aegean"
      itemScope
      itemType={'https://schema.org/TaxiService'}
    >
      <div className="container px-6 py-8 mx-auto">
        <div className="items-center lg:flex">
          <div className="lg:w-1/2">
            <div className="flex flex-col">
              <h4 itemProp="category" className="text-3xl font-bold">
                {title}
              </h4>
              <p
                role={'doc-subtitle'}
                itemProp="availableChannel"
                className="text-lg font-semibold aegean"
              >
                {subtitle}
              </p>
            </div>

            <article itemProp="audience" className="mt-4 text-aegean/95  lg:max-w-md">
              {content &&
                documentToReactComponents(JSON.parse(content), createDescriptionOptions())}
            </article>
          </div>
          <Image data={data} />
        </div>
      </div>
    </section>
  );
};

export default InfoLarge;
