import React from 'react';
import { Description } from '../Description';

const DescriptionView = ({
  infoSections,
  sectionTitleInfo,
  sectionTitleDetails,
  cmsImages,
  mainDescriptionContent,
  contentfulDetails,
  isGRLocale,
}) => {
  return (
    <Description
      sectionTitleDetails={sectionTitleDetails}
      sectionTitleInfo={sectionTitleInfo}
      infoSections={infoSections}
      cmsImages={cmsImages}
      mainDescriptionContent={mainDescriptionContent}
      contentfulDetails={contentfulDetails}
      isGRLocale={isGRLocale}
    />
  );
};

export default DescriptionView;
