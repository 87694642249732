import React from 'react';
import { useTitleStyles } from './style';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { desktopLimit } from '../../constants/media';
import { Typography } from '@material-ui/core';
import '../../styles/icon-scroll.css';

const DescriptionTitle = ({
  sectionTitleInfo,
  paddingBottom = 300,
  marginTop = 0,
  paddingRight = 300,
  paddingLeft = 150,
  marginBottom = 0,
}) => {
  const isDesktop = useMediaQuery(desktopLimit);
  const classes = useTitleStyles(isDesktop, {
    paddingBottom,
    marginTop,
    paddingRight,
    paddingLeft,
    marginBottom,
  })();
  const sectionVariant = isDesktop ? 'h3' : 'h2';
  return (
    <div className={`relative bg-aegean ${classes.descriptionTitleArea}`}>
      <Typography variant={sectionVariant} className={classes.title}>
        {sectionTitleInfo}
      </Typography>
      {isDesktop && <div className={classes.line} />}
    </div>
  );
};

export default DescriptionTitle;
