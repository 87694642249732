import React from 'react';
import { WelcomePage } from '../components/WelcomePage';
import { graphql } from 'gatsby';
import {
  getDescriptionByLocale,
  getDetailsByLocale,
  getDriverDetailsByLocale,
  getImagesByLocale,
  getInfoSectionLocale,
} from '../utils/contentful';
import { StaticQuery } from 'gatsby';
import { useLocaleContext } from '../Providers/LocaleProvider';
import SEO from '../components/SEO/SEO';

const cmsQuery = graphql`
  {
    allContentfulDetails {
      nodes {
        childContentfulDetailsCarInfoTextNode {
          internal {
            content
          }
        }
        childContentfulDetailsCleanInfoTextNode {
          internal {
            content
          }
        }
        childContentfulDetailsRouteInfoTextNode {
          internal {
            content
          }
        }
        node_locale
      }
    }
    allContentfulDescriptionPage {
      nodes {
        node_locale
        mainDescription {
          raw
        }
      }
    }
    allContentfulAsset {
      nodes {
        description
        node_locale
        title
        file {
          url
        }
      }
    }
    allContentfulInfoSection {
      nodes {
        image {
          file {
            url
          }
        }
        title
        subtitle
        mainContent {
          raw
        }
        order
        node_locale
      }
    }
    allContentfulWelcomePage {
      edges {
        node {
          driverName
          driverNumber
          driverSurname
          pageTitle
          metaTitle
          metaDescription
          node_locale
          id
          sectionTitleInfo
          sectionTitleDetails
        }
      }
    }
  }
`;
const HomePage = ({ data }) => {
  const { isGRFlagActive, locale } = useLocaleContext();

  const {
    allContentfulWelcomePage: { edges: welcomePageEntries },
    allContentfulAsset: { nodes: contentfulAssetEntries },
    allContentfulDescriptionPage: { nodes: mainDescriptionEntries },
    allContentfulDetails: { nodes: contentfulDetailEntries },
    allContentfulInfoSection: { nodes: infoSections },
  } = data;

  const welcomePageEntriesLocaled = getDriverDetailsByLocale(welcomePageEntries, locale);
  const galleryImages = getImagesByLocale(contentfulAssetEntries, locale);
  const { mainDescription } = getDescriptionByLocale(mainDescriptionEntries, locale);
  const details = getDetailsByLocale(contentfulDetailEntries, locale);
  const infoSectionsTranslated = getInfoSectionLocale(infoSections, locale);

  return (
    <>
      <SEO pageId={'home'} />

      <WelcomePage
        infoSections={infoSectionsTranslated}
        welcomePageEntries={welcomePageEntriesLocaled}
        cmsImages={galleryImages}
        mainDescriptionContent={mainDescription}
        contentfulDetails={details}
        isGRLocale={isGRFlagActive}
      />
    </>
  );
};

export default function Main(props) {
  return <StaticQuery query={cmsQuery} render={(data) => <HomePage data={data} {...props} />} />;
}
