import React from 'react';
import DescriptionView from '../Views/DescriptionView';
import DescriptionTitle from '../Description/DescriptionTitle';

const WelcomePage = ({
  infoSections = [],
  cmsImages = [],
  welcomePageEntries,
  mainDescriptionContent = '',
  contentfulDetails,
  isGRLocale,
}) => {
  const viewProps = {
    ...welcomePageEntries,
    cmsImages,
    isGRLocale,
    contentfulDetails,
    mainDescriptionContent,
    infoSections,
  };

  return (
    <>
      <div className="max-w-6xl m-auto">
        <DescriptionTitle
          sectionTitleInfo={viewProps.sectionTitleInfo}
          paddingBottom={100}
          marginTop={50}
          marginBottom={50}
          paddingLeft={20}
          paddingRight={20}
        />
      </div>

      <DescriptionView {...viewProps} />
    </>
  );
};

export default WelcomePage;
