import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { desktopLimit } from '../../constants/media';
import { GalleryList } from '../Gallery';

const GridCarouselView = ({ cmsImages }) => {
  const isDesktop = useMediaQuery(desktopLimit);

  return isDesktop ? (
    <div className="gallery-display">
      <GalleryList cmsImages={cmsImages} />
    </div>
  ) : null;
};

export default GridCarouselView;
