import { makeStyles } from '@material-ui/core/styles';
import { commonShadowLayout } from '../../utils/styles';

export const useTitleStyles = (
  isDesktop,
  { paddingBottom = 0, marginTop = 0, paddingRight = 0, paddingLeft = 0, marginBottom = 0 },
) =>
  makeStyles((theme) => ({
    descriptionTitleArea: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: ' space-around',
      padding: `100px 0px ${paddingBottom}px 0px`,
      color: '#f2c75c',
      marginTop,
      marginBottom,
      borderRadius: 6,
    },
    title: {
      fontSize: isDesktop ? '3rem' : '1.5rem',
      fontWeight: 800,
      paddingLeft: isDesktop ? paddingLeft : 0,
      paddingRight: isDesktop ? paddingRight : 0,
    },
    line: {
      flex: 'auto',
      height: 1,
      background: '#f2c75c',
    },
  }));
