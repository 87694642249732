import React from 'react';
import { TaxiIcon, RoadIcon, HealthIcon } from 'components/Icons';
import ReactMarkdown from 'react-markdown';

const ICON_CONFIG = {
  childContentfulDetailsCarInfoTextNode: <TaxiIcon cssClass="flex justify-center m-auto" />,
  childContentfulDetailsCleanInfoTextNode: <HealthIcon cssClass="flex justify-center m-auto" />,
  childContentfulDetailsRouteInfoTextNode: <RoadIcon cssClass="flex justify-center m-auto" />,
};

const InfoSmall = ({ data: { content, type, index } }) => {
  return (
    <section className="max-w-xs mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 mt-3">
      <div className="px-4 py-2">
        <article itemProp="itemOffered" itemScope className="mt-1 text-sm text-aegean/75 ">
          <ReactMarkdown>{content && content}</ReactMarkdown>
        </article>
      </div>

      {ICON_CONFIG[type]}
    </section>
  );
};

export default InfoSmall;
