import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const getCommonItemConfig = () => ({
  [BLOCKS.HEADING_1]: (node, children) => convertHeadingMarkDownNodes(node, children),
  [BLOCKS.HEADING_2]: (node, children) => convertHeadingMarkDownNodes(node, children),
  [BLOCKS.HEADING_3]: (node, children) => convertHeadingMarkDownNodes(node, children),
  [BLOCKS.HEADING_4]: (node, children) => convertHeadingMarkDownNodes(node, children),
});
export const convertMarkDownNodes = (node, children) => {
  return children && <p className="text-base font-light">{children}</p>;
};

export const convertHeadingMarkDownNodes = (node, children) => {
  console.log(children, node);
  return children && <h4>{children}</h4>;
};

export const createDescriptionOptions = () => ({
  renderMark: {
    [MARKS.BOLD]: (text) => {
      return <strong>{text}</strong>;
    },
  },
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => {
      return children && <ul className="list-none">{children}</ul>;
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return (
        children && (
          <li className="list-none flex row-auto mb-2 mt-2 text-aegean">
            <span className="mr-2">
              <CheckCircleOutlineIcon className="aegean" />
            </span>
            {children}
          </li>
        )
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return convertMarkDownNodes(node, children);
    },
    ...getCommonItemConfig(),
  },
});

export const createDetailsItemOptions = () => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => convertMarkDownNodes(node, children),
    [BLOCKS.HEADING_3]: (node, children) => {
      console.log(children);
      return children && <h3 className="text-base font-light">{children}</h3>;
    },
  },
});

export const createPrivacyPolicy = () => ({
  renderMark: {
    [MARKS.BOLD]: (text) => {
      return <strong>{text}</strong>;
    },
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return children && <p className="text-base font-light	mt-2.5">{children}</p>;
    },
    ...getCommonItemConfig(),
  },
});
