import React, { useMemo } from 'react';

const getImages = (cmsImages) => {
  return cmsImages.map(({ file, description }, index) => {
    return {
      img: file.url,
      title: description,
      key: `image_${index}`,
      cols: index === cmsImages.length - 1 ? 2 : 1,
      featured: Boolean(index % 2),
    };
  });
};

const Image = ({ item, extraCssClass }) => (
  <div className={`${extraCssClass} p-1 md:p-2 gallery-image`}>
    <img
      width={1024}
      height={768}
      className="block h-full w-full rounded-lg object-center"
      src={item.img}
      alt={item.title}
      loading="lazy"
    />
    <span className="gallery-image__title">{item.title}</span>
  </div>
);

export default function AdvancedImageList({ cmsImages }) {
  const images = getImages(cmsImages).filter((img) => img.title !== 'taxi-illustation');
  const firstImages = useMemo(() => images.slice(0, images.length / 2), [images]);
  const restImages = useMemo(() => images.slice(images.length / 2, images.length), [images]);

  return (
    <section className="overflow-hidden text-neutral-700">
      <div className="container mx-auto pt-24">
        <div className="-m-1 flex flex-wrap md:-m-2">
          <div className="flex w-1/2 flex-wrap">
            {firstImages.map((item, index) => (
              <Image
                item={item}
                key={item.img}
                extraCssClass={index === 1 || index === 0 ? 'w-1/2' : 'w-full'}
              />
            ))}
          </div>
          <div className="flex w-1/2 flex-wrap">
            {restImages.map((item, index) => (
              <Image item={item} key={item.img} extraCssClass={index === 0 ? 'w-full' : 'w-1/2'} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
