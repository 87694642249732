import React from 'react';
import GridCarouselView from '../Views/GridCarouselView';
import InfoSection from '../InfoSection/InfoSection';
import DescriptionTitle from '../Description/DescriptionTitle';

const Description = ({ contentfulDetails, infoSections = [], sectionTitleDetails, cmsImages }) => {
  return (
    <div id={'taxi-description'} className={`flex flex-col pt-3 max-w-6xl m-auto`}>
      <InfoSection sections={infoSections} itemType={'https://schema.org/Offer'} />
      <DescriptionTitle
        sectionTitleInfo={sectionTitleDetails}
        paddingBottom={100}
        marginTop={50}
        marginBottom={50}
        paddingLeft={20}
        paddingRight={20}
      />
      <InfoSection
        sections={contentfulDetails}
        componentType="small"
        extraCssClass="lg:flex-row flex-col"
        itemType={'https://schema.org/TaxiService'}
      />
      <GridCarouselView cmsImages={cmsImages} />
    </div>
  );
};

export default Description;
