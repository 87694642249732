export const convertInfoLgToViewModel = (infoDto) => ({
  imgSrc: infoDto.image?.file?.url,
  content: infoDto.mainContent?.raw,
  order: infoDto.order,
  subtitle: infoDto.subtitle,
  title: infoDto.title,
});

export const convertInfoSmToViewModel = (infoDto) => (dataType, index) => {
  return { type: dataType, content: infoDto[dataType].internal?.content, index };
};
