import React from 'react';
import InfoLarge from './InfoLarge';
import {
  convertInfoLgToViewModel,
  convertInfoSmToViewModel,
} from '../../converters/infoSectionConverter';
import InfoSmall from './InfoSmall';

const InfoSection = ({
  sections,
  componentType = 'large',
  extraCssClass = 'flex-col',
  itemType = '',
}) => {
  const largeInfoSectionData =
    componentType === 'large' &&
    sections &&
    sections?.length &&
    sections.map(convertInfoLgToViewModel);
  const smallInfoSectionDataTypes = componentType === 'small' && sections && Object.keys(sections);

  const smallInfoSectionData =
    smallInfoSectionDataTypes?.length &&
    smallInfoSectionDataTypes.map(convertInfoSmToViewModel(sections));

  const getKey = (i) => `info_${componentType}_${i}`;

  return (
    <div className={`flex ${extraCssClass}`} itemScope itemType={itemType}>
      {componentType === 'large' &&
        largeInfoSectionData &&
        largeInfoSectionData?.map((data, i) => {
          return <InfoLarge key={getKey(i)} data={data} index={i} />;
        })}
      {componentType === 'small' &&
        smallInfoSectionData?.map((data, i) => {
          return <InfoSmall key={getKey(i)} data={data} />;
        })}
    </div>
  );
};

export default InfoSection;
